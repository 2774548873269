const actions = {
  INIT_SENDOUT: "INIT_SENDOUT",
  SET_COMMENT: "SET_COMMENT",
  SET_TEXT: "SET_TEXT",
  SET_DISPLAY_NAME: "SET_DISPLAY_NAME",
  SET_DISPLAY_TJM: "SET_DISPLAY_TJM",
  SELECT_REF: "SELECT_REF",
  EDIT_REF: "EDIT_REF",
  ADD_REF: "ADD_REF",
  SELECT_FILE: "SELECT_FILE",
  initSendout: (payload) => ({
    type: actions.INIT_SENDOUT,
    payload,
  }),
  setComment: (payload) => ({
    type: actions.SET_COMMENT,
    payload,
  }),
  setTextField: (payload) => ({
    type: actions.SET_TEXT,
    payload,
  }),
  setDisplayName: (payload) => ({
    type: actions.SET_DISPLAY_NAME,
    payload,
  }),
  setDisplayTJM: (payload) => ({
    type: actions.SET_DISPLAY_TJM,
    payload,
  }),
  selectReference: (payload) => ({
    type: actions.SELECT_REF,
    payload,
  }),
  editReference: (payload) => ({
    type: actions.EDIT_REF,
    payload,
  }),
  addReference: (payload) => ({
    type: actions.ADD_REF,
    payload,
  }),
  selectFile: (payload) => ({
    type: actions.SELECT_FILE,
    payload,
  }),
};

export default actions;
