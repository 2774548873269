const actions = {
  SET_BHENTITY: "SET_BHENTITY",

  setBhEntity: (payload) => ({
    type: actions.SET_BHENTITY,
    payload,
  }),
};

export default actions;
