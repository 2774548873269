import actions from "./actions";

const initState = {
  filtredContract: [],
};

export default function FiltredContractReducer(state = initState, action) {
  if (action.type === actions.SET_FILTRED_CONTRACT) {
    return {
      ...state,
      filtredContract: action.payload,
    };
  }
  return state;
}
