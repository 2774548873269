import actions from "./actions";

const initState = {
  reportKey: "GeneralMetrics",
};

export default function appReducer(state = initState, action) {
  if (action.type === actions.SET_REPORT_KEY) {
    return {
      ...state,
      reportKey: action.payload,
    };
  }
  return state;
}
