const actions = {
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  SET_USER: "SET_USER",
  LOGOUT: "LOGOUT",
  SET_TOKEN: "SET_TOKEN",
  login: (payload) => ({
    type: actions.LOGIN_SUCCESS,
    payload,
  }),
  setToken: (payload) => ({
    type: actions.SET_TOKEN,
    payload,
  }),
  setUser: (payload) => ({
    type: actions.SET_USER,
    payload,
  }),
  logout: (payload) => ({
    type: actions.LOGOUT,
    payload,
  }),
};

export default actions;
