import {
  InMemoryCache,
  ApolloLink,
  defaultDataIdFromObject,
} from "apollo-boost";
import ApolloClient from "apollo-client";
import { createUploadLink } from "apollo-upload-client";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const cache = new InMemoryCache({
  addTypename: false,
  dataIdFromObject: (object) => {
    switch (object.__typename) {
      case "User":
        return defaultDataIdFromObject({ id: object.userID, ...object });
      default:
        return defaultDataIdFromObject(object);
    }
  },
});

const uploadLink = createUploadLink({
  uri: process.env.REACT_APP_GRAPHQL_EP,
  fetch,
});
const authLink = new ApolloLink((operation, forward) => {
  const token = cookies.get("AUTH_TOKEN") || localStorage.getItem("token");

  // Pass token to headers
  operation.setContext({
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
    },
  });

  return forward(operation);
});

const client = new ApolloClient({
  cache,
  link: ApolloLink.from([authLink, uploadLink]),
});

export default client;
