import { combineReducers } from "redux";
import AppReducer from "./app/reducer";
import AuthReducer from "./auth/reducer";
import BhEntityReducer from "./bhEntity/reducer";
import UserReducer from "./users/reducer";
import PermissionReducer from "./permissions/reducer";
import SendoutReducer from "./sendout/reducer";
import MissedDealReducer from "./missedDeal/reducer";
import xeroLoginReducer from "./xeroLogin/reducer";
import CheckboxChoicesReducer from "./checkboxChoices/reducer";
import ListMissedDealReducer from "./listMissedDeal/reducer";
import ListMarketInfoReducer from "./listMarketInfo/reducer";
import PowerBIReportsReducer from "./powerBIReports/reducer";
import PowerBIReportKeyReducer from "./powerBIReportKey/reducer";
import MetricToBeAddedReducer from "./metricToBeAdded/reducer";
import FiltredContractReducer from "./filtredContract/reducer";
import FilteredTimsheetReducer from "./filteredTimsheet/reducer";
import mqCvGeneratorReducer from "./mqCvGenerator/reducer";

export default combineReducers({
  AppReducer,
  AuthReducer,
  BhEntityReducer,
  UserReducer,
  PermissionReducer,
  SendoutReducer,
  MissedDealReducer,
  xeroLoginReducer,
  CheckboxChoicesReducer,
  ListMissedDealReducer,
  ListMarketInfoReducer,
  PowerBIReportsReducer,
  PowerBIReportKeyReducer,
  MetricToBeAddedReducer,
  FiltredContractReducer,
  FilteredTimsheetReducer,
  mqCvGeneratorReducer,
});
