import Cookies from "universal-cookie";
import { message } from "antd";
import actions from "./actions";
import { cookieRemoveOpt } from "../../configs/domainConfigs";

const cookies = new Cookies();

let validToken = null;
try {
  validToken = localStorage.getItem("token") || cookies.get("AUTH_TOKEN");
} catch (e) {
  message.error("Please enable cookies for private navigation.", 10);
}

const initState = {
  token: validToken,
  user: null,
};

export default function appReducer(state = initState, action) {
  if (action.type === actions.LOGIN_SUCCESS) {
    return {
      ...state,
      token: action.payload.token,
      user: action.payload,
    };
  }
  if (action.type === actions.SET_TOKEN) {
    return {
      ...state,
      token: action.payload,
    };
  }
  if (action.type === actions.SET_USER) {
    return {
      ...state,
      user: action.payload,
    };
  }

  if (action.type === actions.LOGOUT) {
    localStorage.removeItem("token");
    cookies.remove("AUTH_TOKEN", cookieRemoveOpt);
    // payload is the cache of apollo client
    action.payload.reset();
    return {
      ...state,
      user: null,
      token: null,
    };
  }

  return state;
}
