const actions = {
  SET_LIST_MISSEDDEAL: "SET_LIST_MISSEDDEAL",

  setListMissedDeal: (payload) => ({
    type: actions.SET_LIST_MISSEDDEAL,
    payload,
  }),
};

export default actions;
