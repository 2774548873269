const actions = {
  SET_LIST_MARKETINFO: "SET_LIST_MARKETINFO",

  setListMarketInfo: (payload) => ({
    type: actions.SET_LIST_MARKETINFO,
    payload,
  }),
};

export default actions;
