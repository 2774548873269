import actions from "./actions";

const initState = {
  xeroToken: false,
};
export default function xeroLoginReducer(state = initState, action) {
  if (action.type === actions.VERIF_TOKEN) {
    return {
      ...state,
      xeroToken: action.payload,
    };
  }
  return state;
}
