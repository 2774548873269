const actions = {
  SET_MISSEDDEAL: "SET_MISSEDDEAL",

  setMissedDeal: (payload) => ({
    type: actions.SET_MISSEDDEAL,
    payload,
  }),
};

export default actions;
