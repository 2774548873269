const actions = {
  INIT_TIMSHEET:"INIT_TIMSHEET",
  SET_FILTRED_TIMESHEET: "SET_FILTRED_TIMESHEET",

  initTimesheet: (payload) => ({
    type: actions.INIT_TIMSHEET,
    payload,
  }),
  
  setFilteredTimsheet: (payload) => ({
    type: actions.SET_FILTRED_TIMESHEET,
    payload,
  }),
};

export default actions;
