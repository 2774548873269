import actions from "./actions";

const initState = {
  listMarketInfo: null,
};

export default function ListMarketInfoReducer(state = initState, action) {
  if (action.type === actions.SET_LIST_MARKETINFO) {
    return {
      ...state,
      listMarketInfo: action.payload,
    };
  }
  return state;
}
