const actions = {
  SET_FILTRED_CONTRACT: "SET_FILTRED_CONTRACT",

  setFiltredContract: (payload) => ({
    type: actions.SET_FILTRED_CONTRACT,
    payload,
  }),
};

export default actions;
