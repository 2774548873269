import React, { lazy, Suspense } from "react";
import {
  Route,
  Redirect,
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";
import { useSelector } from "react-redux";

import ErrorBoundary from "./ErrorBoundary";

const Dashboard = lazy(() => import("./containers/Layout/Layout"));
const Login = lazy(() => import("./containers/Login/Login"));
const ForgotPassword = lazy(() =>
  import("./containers/ResetPassword/ForgotPassword")
);
const ResetPassword = lazy(() =>
  import("./containers/ResetPassword/ResetPassword")
);
// eslint-disable-next-line react/prop-types
function PrivateRoute({ children, ...rest }) {
  const isLoggedIn = useSelector((state) => state.AuthReducer.token);
  if (isLoggedIn) return <Route {...rest}>{children}</Route>;
  return (
    <Redirect
      to={{
        pathname: "/login",
      }}
    />
  );
}
export default function Routes() {
  return (
    <ErrorBoundary>
      <Suspense fallback={<p>Loading...</p>}>
        <Router>
          <Switch>
            <Route component={Login} path="/" exact />
            <Route component={Login} path="/login" exact />
            <Route component={ForgotPassword} path="/forgotPassword" exact />
            <Route
              component={ResetPassword}
              path="/password/reset/:userId/:resetToken"
              exact
            />
            <PrivateRoute path="/home">
              <Dashboard />
            </PrivateRoute>
          </Switch>
        </Router>
      </Suspense>
    </ErrorBoundary>
  );
}
