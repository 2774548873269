import actions from "./actions";

const initState = {
  input: {
    report: null,
    permission: null,
    dateStart: null,
    dateAdded: null,
    IPAdress: null,
  },
};

export default function appReducer(state = initState, action) {
  if (action.type === actions.SET_METRICTOBEADDED) {
    return {
      ...state,
      input: action.payload,
    };
  }
  return state;
}
