import actions from "./actions";

const initState = {
  listMissedDeal: null,
};

export default function ListMissedDealReducer(state = initState, action) {
  if (action.type === actions.SET_LIST_MISSEDDEAL) {
    return {
      ...state,
      listMissedDeal: action.payload,
    };
  }
  return state;
}
