const format = (url) => {
  const last = url.length - 1;
  if (url[last] === "/") return url?.substring(0, last);
  return url;
};

const runtimeVars = {
  devMode: process.env.NODE_ENV === "development",
  testMode: process.env.ENV_MODE === "test",
  DOMAIN: process.env.REACT_APP_DOMAIN || "localhost",
  gtmCode: process.env.GTM_CODE || "",
  TITLE: process.env.SITE_TITLE || "Club Freelance",
  // FRONTEND_BASE_URL: format(process.env.RAZZLE_FRONTEND_BASE_URL),
  APP_GRAPHQL_EP: format(process.env.REACT_APP_GRAPHQL_EP),
  APP_LANG: process.env.RAZZLE_APP_LANG || "fr",
  APP_COUNTRY_ID: process.env.RAZZLE_APP_COUNTRY_ID || "2245",
  APP_CAPTCHA_KEY: process.env.RAZZLE_APP_CAPTCHA_KEY,
  VERSION: process.env.RAZZLE_VERSION,
  RESSOURCES_URL: process.env.RESSOURCES_URL || "https://ressources.mindquest.io",
  TOOLS_LINK: process.env.REACT_APP_TOOLS_LINK || "https://tools.club-freelance.fr"
};

export default runtimeVars;
