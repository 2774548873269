import actions from "./actions";

const initState = {
  modalVisible: false,
  user: {},
};

export default function usersReducer(state = initState, action) {
  if (action.type === actions.TOGGLE_USER_MODAL) {
    return {
      ...state,
      modalVisible: !state.modalVisible,
      user: action.payload,
    };
  }

  return state;
}
