import actions from "./actions";

const initState = {
  sendout: {},
  displayName: true,
  displayTJM: true,
  selectedReferences: [],
  selectedFile: [],
};

export default function sendoutReducer(state = initState, action) {
  if (action.type === actions.INIT_SENDOUT) {
    return {
      ...state,
      sendout: action.payload,
    };
  }

  if (action.type === actions.SET_COMMENT) {
    return {
      ...state,
      sendout: { ...state.sendout, comments: action.payload },
    };
  }

  if (action.type === actions.SET_TEXT) {
    const { fieldName, value } = action.payload;
    return {
      ...state,
      sendout: { ...state.sendout, [fieldName]: value },
    };
  }

  if (action.type === actions.SET_DISPLAY_NAME) {
    return {
      ...state,
      displayName: action.payload,
    };
  }

  if (action.type === actions.SET_DISPLAY_TJM) {
    return {
      ...state,
      displayTJM: action.payload,
    };
  }

  if (action.type === actions.SELECT_REF) {
    return {
      ...state,
      selectedReferences: action.payload,
    };
  }

  if (action.type === actions.EDIT_REF) {
    if (Array.isArray(state.sendout.reference)) {
      const newRefs = state.sendout.reference.map((ref) => {
        if (ref.id !== action.payload.id) {
          return ref;
        }
        return {
          ...ref,
          ...action.payload,
        };
      });

      return {
        ...state,
        sendout: {
          ...state.sendout,
          reference: newRefs,
        },
      };
    }

    return state;
  }

  if (action.type === actions.ADD_REF) {
    const newRef = {
      id: action.payload,
      referenceFirstName: "",
      referenceLastName: "",
      referenceTitle: "",
      outcome: "",
      __typename: "CandidateReference",
    };

    return {
      ...state,
      sendout: {
        ...state.sendout,
        reference: [newRef, ...state.sendout.reference],
      },
    };
  }

  if (action.type === actions.SELECT_FILE) {
    return {
      ...state,
      selectedFile: action.payload,
    };
  }

  return state;
}
