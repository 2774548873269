import actions from "./actions";

const initState = {
  modalVisible: false,
  permission: {},
};

export default function permissionsReducer(state = initState, action) {
  if (action.type === actions.TOGGLE_PERMISSION_MODAL) {
    return {
      ...state,
      modalVisible: !state.modalVisible,
      permission: action.payload,
    };
  }

  return state;
}
