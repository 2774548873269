import actions from "./actions";

const initState = {
  modalVisible: false,
  powerBIReport: {},
};

export default function powerBIReportsReducer(state = initState, action) {
  if (action.type === actions.TOGGLE_POWER_BI_REPORT_MODAL) {
    return {
      ...state,
      modalVisible: !state.modalVisible,
      powerBIReport: action.payload,
    };
  }

  return state;
}
