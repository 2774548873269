import actions from "./actions";

const initState = {
  filteredTimsheet: {
    status: null,
    internal: null,
    timesheetID: null,
    clientName: null,
    candidateName: null,
    placementID: null,
    limit: 10,
    skip: 0,
    name: null,
    periodStart: null,
    periodEnd: null,
    month: null,
    year: null,
  },
};

export default function FilteredTimsheetReducer(state = initState, action) {
  if (action.type === actions.INIT_TIMSHEET) {
    return {
      ...state,
      filteredTimsheet: action.payload,
    };
  }
  if (action.type === actions.SET_FILTRED_TIMESHEET) {
    return {
      ...state,
      filteredTimsheet: action.payload,
    };
  }
  return state;
}
