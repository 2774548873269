import runtimeVars from "./runTimeVars";

// expiration date for the cookie: 1 year
const after1year = new Date();
after1year.setMonth(after1year.getMonth() + 12);

const cookieSaveOpt = {
  path: "/",
  sameSite: false,
  domain: runtimeVars.DOMAIN,
  expires: after1year,
};
const cookieRemoveOpt = {
  path: "/",
  domain: runtimeVars.DOMAIN,
};

export { cookieSaveOpt, cookieRemoveOpt };
