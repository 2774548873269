/* const actions = {
  SET_CHOICES: "SET_CHOICES",

  setChoices: (payload) => ({
    type: actions.SET_CHOICES,
    payload,
  }),
};

export default actions; */

const actions = {
  SET_CHOICES: "SET_CHOICES",
  SET_INPUT_LIST: "INPUT_LIST",
  SET_ENTITY_BH: "SET_ENTITY_BH",
  SET_ENTITY_FIELDS: "SET_ENTITY_FIELDS",
  ADD_INPUT_LIST: "ADD_INPUT_LIST",

  setChoices: (payload) => ({
    type: actions.SET_CHOICES,
    payload,
  }),
  addInputList: (payload) => ({
    type: actions.ADD_INPUT_LIST,
    payload,
  }),
  setInputList: (payload) => ({
    type: actions.SET_INPUT_LIST,
    payload,
  }),
  setEntityBH: (payload) => ({
    type: actions.SET_ENTITY_BH,
    payload,
  }),
  setEntityFields: (payload) => ({
    type: actions.SET_ENTITY_FIELDS,
    payload,
  }),
};

export default actions;
