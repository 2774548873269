/* import actions from "./actions";

const initState = {
  choices: [],
};

export default function appReducer(state = initState, action) {
  if (action.type === actions.SET_CHOICES) {
    return {
      ...state,
      choices: action.payload,
    };
  }
  return state;
}
*/

import actions from "./actions";

const initState = {
  choices: {},
  inputList: [],
  entityBH: "",
  entityFields: [],
};

export default function appReducer(state = initState, action) {
  if (action.type === actions.SET_CHOICES) {
    const { name, tabChoices } = action.payload;
    const cloneChoices = { ...state.choices };
    cloneChoices[name] = tabChoices;
    return {
      ...state,
      choices: cloneChoices,
    };
  }

  if (action.type === actions.ADD_INPUT_LIST) {
    const { payload } = action;
    return {
      ...state,
      inputList: [...state.inputList, payload],
    };
  }

  if (action.type === actions.SET_INPUT_LIST) {
    const { payload } = action;
    return {
      ...state,
      inputList: payload,
    };
  }

  if (action.type === actions.SET_ENTITY_BH) {
    const { payload } = action;
    return {
      ...state,
      entityBH: payload,
    };
  }

  if (action.type === actions.SET_ENTITY_FIELDS) {
    const { payload } = action;
    return {
      ...state,
      entityFields: payload,
    };
  }

  return state;
}
