import actions from "./actions";

const initState = {
  bhEntity: { entityType: null, entityId: null },
};

export default function appReducer(state = initState, action) {
  if (action.type === actions.SET_BHENTITY) {
    return {
      ...state,
      bhEntity: action.payload,
    };
  }
  return state;
}
